import React, {useCallback} from 'react';
import {
  Icon,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import {ExternalMinor} from '@shopify/polaris-icons';
import PlusLogoGreen from '../../../../../images/PlusLogoGreen.png';
import './checkoutExtensionGuide.css';

const CustomizationTab = (props) => {
  const {title, content} = props;

  const onClickHandler = useCallback(() => {
    window.open(`https://apps.shopify.com/customcheckoutplus`, '_blank')
  }, []);

  return (
    <div className='Guide-content__item' onClick={() => onClickHandler()}>
      <Stack distribution='equalSpacing'>
        <Stack vertical spacing='extraTight'>
          <TextStyle>
            {title}
          </TextStyle>
          <TextStyle variation='subdued'>
            {content}
          </TextStyle>
        </Stack>

        <Stack alignment='center' spacing='tight'>
          <Icon source={ExternalMinor} color='base'/>
          <img src={PlusLogoGreen} alt={'PlusLogoGreen'} className='Guide__icon'/>
        </Stack>
      </Stack>
    </div>
  );
};

export default CustomizationTab;